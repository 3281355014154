

    import {Options, Vue} from "vue-class-component";
    import {ProductDetail} from "@/types";
    import moment from "moment";

    // nuevos
    import {User} from "@/types";
    import {store} from "@/store";
    import {postComment, postQuestion} from "@/data/apiProduct";
    import Session from "@/services/session";
    import {PropType} from "vue";

    @Options({
      props: {
        product: Object as PropType<ProductDetail>
      }
    })
    export default class TheProductTabs extends Vue {
        private product !: ProductDetail
        private tabProductSelected = 0
        private isLoggedIn = store.getters.loggedIn
        private modelQuestion = ""
        private modelComment = ""


        private newComment = ""
        private firstName = ""
        private lastName = ""
        private user !: User
        private time = ""


        private getFormatDate(date: string): string {
            return moment(String(date)).format('DD/MM/YY - hh:mm')
        }

        submitQuestion(e: Event): void {
            store.commit('showLoading', 'Enviando pregunta...')
            const body: Record<string, unknown> = {question: this.modelQuestion, idProduct: this.product.id}
            postQuestion(body)
                .then((response) => {
                  console.log(response)
                    this.$toast.success("Pregunta enviada con exito")
                    store.commit('hideLoading')

                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.$toast.error(error.response.data.message)

                    } else {
                        this.$toast.error("Error  al  enviar la Pregunta")

                    }

                })
            e.preventDefault();
        }

        submitComment(e: Event): void {
            store.commit('showLoading', 'Enviando comentario...')
            const body: Record<string, unknown> = {comment: this.modelComment, idProduct: this.product.id}
            postComment(body)
                .then((response) => {
                  console.log(response)
                    this.user = Session.getUser()
                    this.newComment = this.modelComment
                    if (this.user.attributes.name) {
                        const fullName = this.user.attributes.name.toString().split(" ") //angelo steve -> angelo
                        this.firstName = fullName[0]
                    }

                    if (this.user.attributes.surname) {
                        const allName = this.user.attributes.surname.toString().split(" ") //quijano orduña -> quijano
                        this.lastName = allName[0]
                    }

                    this.modelComment = ""
                    this.time = moment().format('DD/MM/YY - h:mm:ss a');


                    this.$toast.success("Comentario enviado con exito")
                    store.commit('hideLoading')

                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.$toast.error(error.response.data.message)

                    } else {
                        this.$toast.error('Error  al  enviar el comentario')
                    }

                })
            e.preventDefault();
        }
    }
