import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/css/mdb.min.css";
import 'mdb-vue-ui-kit/css/mdb.min.css'
import "vue-toast-notification/dist/theme-sugar.css";
import 'vue3-carousel/dist/carousel.css';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import vClickOutside from "click-outside-vue3"
import lineClamp from "vue-line-clamp"
import VueToast from "vue-toast-notification"

createApp(App)
    .use(store)
    .use(router)
    .use(vClickOutside)
    .use(lineClamp)
    .use(VueToast, {
        position: "bottom-right",
    })
    .mount('#app')
