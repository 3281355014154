
import {Options, Vue} from "vue-class-component";
import {Color, CustomDropDownItem, Presentation, ProductDetail, SlidePhoto, StockOption, Combo, Frequent} from "@/types";
import vSelect from 'vue-select'
import {mdbCarousel} from 'mdbvue'
import {mdbModal, mdbModalBody} from 'mdbvue'
import CustomDropdown from "@/components/CustomDropdown.vue";
import PhotoOptionCarousel from "@/components/PhotoOptionCarousel.vue";
import router from "@/router";
import GLOBAL from "@/config/global";
import {store} from "@/store";
import ProductCarousel from "@/components/ProductCarousel.vue"
import TheGarmentPopup from "@/components/TheGarmentPopup.vue"
import TheProductTabs from "@/components/TheProductTabs.vue"
import InnerImageZoom from 'vue-inner-image-zoom'
import {getCombo, getFrequent, getProduct, getRelated} from "@/data/apiProduct";
import ProductCardSelectable from "@/components/ProductCardSelectable.vue";

@Options({
  components: {
    ProductCarousel,
    TheGarmentPopup,
    PhotoOptionCarousel,
    CustomDropdown,
    vSelect,
    mdbCarousel,
    mdbModalBody,
    mdbModal,
    TheProductTabs,
    InnerImageZoom,
    ProductCardSelectable
  },
  watch: {
    currentQuery(value) {
      if(value){
        store.commit("showLoading")
        this.initProduct()
      }
    }
  }
})
export default class ProductMain extends Vue {

  slides: SlidePhoto[] = []
  product: ProductDetail | null = null
  modalShoes = false
  quantity = 1
  currentPrice = 0
  currentColor: Color | null = null
  currentPresentationState: number | null = null
  currentPresentation: Presentation | null = null
  currentPhoto = ""
  sizeOptions: CustomDropDownItem[] = [];
  collectionOptions: CustomDropDownItem[] = [];
  collectionSelected = '';
  nameColor = ""
  nameCollection = ""
  hoverColor = false
  hoverCollection = false
  sizeSelected = "Seleccionar..."
  tabMeasureSelected = 0
  tabProductSelected = 0
  showRelated = false
  sizeSelectable = true
  siteUrl = GLOBAL.PUBLIC_URL
  related = []
  combos: Combo[] = []
  frequents: Frequent[] = []

  created() {
    store.commit("showLoading")
    this.slides = []
    this.initProduct()
  }

  async initProduct() {
    const slug = router.currentRoute.value.params.slug.toString().split("-")
    const idProduct = slug[slug.length - 1]

    const {code, message, payload} = await getProduct(idProduct)
    store.commit("hideLoading")
    if(code === 200) {
      this.slides = []
      this.product = payload.product
      if (router.currentRoute.value.query.color) {
        this.selectColor(this.product?.colors.find(res => String(res.id) === router.currentRoute.value.query.color) ?? null)
      }else{
        this.selectColor(this.product?.colors[0] ?? null)
      }
      if (this.product?.attributes.dimensions === "onlyone" && this.product?.presentations[0]) {
        this.sizeSelectable = false
        this.selectPresentation({
          class: "",
          label: this.product?.presentations[0].name,
          value: this.product?.presentations[0].id
        })
      }

      // this.currentPrice = Number(this.product?.price)
      if (this.product?.collection && this.product?.collection.length > 0) {
        this.product?.collection.map((item) => {
          this.collectionOptions.push({
            class: '',
            label: item.brand + ' ' + item.name,
            value: item.id
          })
          if (item.id === this.product?.id)
            this.collectionSelected = this.product?.attributes.brand + ' ' + this.product?.name
        })
      }
    }else{
      console.log(message)
    }
    await this.initRelated()
    await this.initCombo()
    await this.initFrequent()
  }

  async initRelated(){
    const {code, payload} = await getRelated(this.product?.id as number)
    if(code === 200 && payload) {
      this.related = payload.related
    }
  }

  async initCombo(){
    const {code, payload} = await getCombo(this.product?.id as number)
    if(code === 200 && payload) {
      this.combos = payload.combos
    }
  }

  async initFrequent(){
    const {code, payload} = await getFrequent(this.product?.id as number)
    if(code === 200 && payload) {
      this.frequents = []
      payload.frequents.forEach((frequent: Frequent) => {
        const products = frequent.products.map((el: ProductDetail) => ({
          ...el,
          selected: true
        }))
        console.log('products', products)
        this.frequents.push({
          ...frequent,
          products
        })
      })
      console.log('payload.frequents.products', this.frequents)
    }
  }

  get currentQuery() {
    return router.currentRoute.value.params.slug
  }

  get price(): number {
    if(this.currentColor && this.currentPresentation) {
      this.currentPrice = this.product?.stock[this.currentColor.id][this.currentPresentation.id].price ?? 0
    }else{
      this.currentPrice = this.product?.price ?? 0
    }
    if (this.product?.offer) {
      if (this.product.offer.percent > 0 && this.product.offer.method === '0') {
        return this.currentPrice * (1 - (Number(this.product.offer.percent) / 100))
      }
      if (this.product.offer.percent > 0 && this.product.offer.method === '1') {
        return this.currentPrice
      }
    }
    return this.currentPrice
  }

  moreQuantity() {
    this.quantity++
  }

  lessQuantity() {
    if (this.quantity > 1)
      this.quantity--
  }

  get strike(): unknown {
    if (this.product?.offer) {
      if (this.product.offer.percent > 0 && this.product.offer.method === '0') {
        return this.currentPrice
      }
      if (this.product.offer.percent > 0 && this.product.offer.method === '1') {
        return (this.currentPrice / (1 - (this.product.offer.percent / 100))).toFixed(2)
      }
    }
    return null
  }

  selectColor(color: Color | null) {
    if (color) {
      color.class = "enable"
      this.currentColor = color
      this.currentPhoto = GLOBAL.PUBLIC_URL + color.photos[0].path
      this.slides = []
      color.photos.forEach(photo => {
        this.slides.push({
          img: true,
          src: GLOBAL.PUBLIC_URL + photo.path,
          id: photo.id
        })
      })
      if (this.product?.attributes.dimensions !== "onlyone") {
        this.setPresentationOptions()
      }
    }
  }

  selectPresentation(presentation: CustomDropDownItem | null) {
    this.currentPresentation = null
    if (presentation) {
      this.sizeSelected = presentation.label
      this.product?.presentations.forEach(item => {
        if (item.id === presentation.value) {
          this.currentPresentation = item
        }
      })
      this.product?.colors.forEach((color) => {
        color.class = "enable"
        if (this.product?.stock[color.id] && this.product?.stock[color.id][presentation.value]) {
          const stockOption: StockOption = this.product?.stock[color.id][presentation.value]
          if (stockOption.stock <= 0) {
            color.class = "unable"
          }
        } else {
          if (presentation.value !== 0) {
            color.class = "unable"
          }
        }
      })
      this.showRelated = presentation.value > 0;
    }
  }

  getClassesColor(color: Color): string {
    let classes = ""
    if (this.currentColor === color) {
      if (color.class !== "unable") {
        classes = "color-active"
      } else {
        let doit = true
        if(this.product && this.product.colors){
          this.product?.colors.forEach(item => {
            if (item.class !== "unable" && doit) {
              this.selectColor(item)
              doit = false
            }
          })
        }
      }
    }
    if (color.class)
      classes = classes + " " + color.class
    return classes
  }

  setPresentationOptions() {
    this.sizeOptions = []
    this.product?.presentations.forEach((presentation) => {
      presentation.class = "text-lightgray"
      if (this.currentColor && this.product && this.product?.stock[this.currentColor.id] && this.product?.stock[this.currentColor.id][presentation.id]) {
        const stockOption: StockOption = this.product?.stock[this.currentColor.id][presentation.id]
        if (stockOption.stock > 0) {
          presentation.class = ""
        }
      }
      this.sizeOptions.push({
        label: presentation.name,
        class: presentation.class,
        value: presentation.id
      })
    })

    if (this.currentPresentation?.class === "text-lightgray") {
      this.sizeSelected = "Seleccionar..."
      this.selectPresentation({
        label: "Selecionar...",
        class: '',
        value: 0
      })
    }

  }

  selectCollection(collection: CustomDropDownItem | null) {
    console.log(collection)
    if (collection) {
      this.product?.collection.forEach(item => {
        if (item.id === collection.value) {
          this.collectionSelected = collection.label
          router.push(({name: 'Product', params: {slug: item.slug}}))
          location.reload()
        }
      })

    }
  }

  updatePreview(url: string): void {
    this.currentPhoto = url
  }

  goToCollection(slug: string): void {
    router.push(slug).then(() => {
      store.commit("showLoading")
      this.initProduct()
    })
  }
}
