import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading d-flex align-items-center"
}
const _hoisted_2 = { class: "text-center w-100" }
const _hoisted_3 = { class: "flex-grow-1 d-flex flex-column bg-gray" }
const _hoisted_4 = { class: "container pt-3 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message) + "...", 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_TheHeader),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_TheFooter),
    (_ctx.showPanel)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["modal-backdrop", {'show fade': _ctx.showPanel }])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 64))
}