
import { Options, Vue } from 'vue-class-component';
import HeaderSearchForm from "@/components/template/HeaderSearchForm.vue";
import TheCategoryPanel from "@/components/template/TheCategoryPanel.vue";
import LoginForm from "@/components/LoginForm.vue";
import {User} from "@/types";
import {
  MDBModal,
  MDBModalBody
} from 'mdb-vue-ui-kit';
import Session from "@/services/session";

@Options({
  components: {LoginForm, TheCategoryPanel, TheHeaderForm: HeaderSearchForm, MDBModalBody, MDBModal}
})
export default class Header extends Vue {

  loggedIn = Session.isLoggedIn()
  modal = false
  user !: User
  firstName = ""

  created() {
    if (this.loggedIn) {
      this.user = Session.getUser()
      console.log(this.user)
      if (this.user.attributes.name) {
        const fullName = this.user.attributes.name.toString().split(" ")
        this.firstName = fullName[0]
      }
    }
  }

  goToRecovery(): void {
    this.modal = false
    this.$router.push("/password")// para ir a register sin recargar
  }

  goToRegister(): void {
    this.modal = false
    this.$router.push("/register")// para ir a register sin recargar
  }

  goToHome(): void {
    if (this.$router.currentRoute.value.name === 'Home') {
      this.$router.go(0)
    } else {
      this.$router.push('/')
    }
  }
}
