
import { Options, Vue } from 'vue-class-component';
import {mapState} from "vuex";
import {store} from "@/store";
import router from "@/router";

@Options({
  computed: mapState(["searched", "showPanel"]),
  watch: {
    searched(value) {
      this.local.searching = value
    }
  }
})
export default class HeaderSearchForm extends Vue {
  reference !: string
  showPanel !: boolean

  local = {
    searching: ""
  }

  togglePanel(): void {
    store.commit("togglePanel")
  }

  goToSearch(): void {
    store.state.searching = this.local.searching
    store.state.searchingByPanel = true
    if(router.currentRoute.value.path !== '/search')
      router.push("/search?reference=" + this.local.searching)
  }



}
