
import { Options, Vue } from 'vue-class-component';
import { Category, Product} from "@/types";
import ProductCarousel from "@/components/ProductCarousel.vue";
import { MDBCarousel } from 'mdb-vue-ui-kit';
import {getCategories, getProductsOnFlash, getSlider} from '@/data/apiHome';
import GLOBAL from "@/config/global";

@Options({
  components: {
    ProductCarousel,
    MDBCarousel
  }
})
export default class HomeMain extends Vue {

  categories: Category[] = []
  onFlash: Product[] = []
  interval = 3000
  banners: unknown[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slider: any

  async created() {
    this.onFlash = await getProductsOnFlash()
    this.categories = await getCategories()
    this.slider = await getSlider()
    this.initBanner()
    this.clickar()
  }

  private async initBanner() {
    if(this.slider.banners)
      for(let i = 0; i < this.slider.banners.length; i++)
        this.banners.push({
          src: GLOBAL.PUBLIC_URL + this.slider.banners[i].image,
        })
    if(this.slider.intervalBanner)
      this.interval = this.slider.intervalBanner * 1000
  }
  async clickar() {
    setTimeout(() => {
      const carouselItems = document.getElementsByClassName('carousel-item')
      if(this.slider.banners && carouselItems.length > 0)
        for(let i = 0; i < this.slider.banners.length; i++) {
          if (this.slider.banners[i].link) {
            carouselItems[i].setAttribute('onclick', `window.location.href = '${ this.slider.banners[i].link }'`)
          }
        }
    }, 1500)
  }

}
