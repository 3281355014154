
import { Options, Vue } from 'vue-class-component';
import Header from "@/components/template/Header.vue";
import TheFooter from "@/components/template/TheFooter.vue";
import {mapState} from "vuex";
import {store} from "@/store";

@Options({
  components: {TheFooter, TheHeader: Header},
  computed: mapState(["loading", "message", "showPanel"])
})
export default class App extends Vue {

  private loading !: boolean
  private message !: string
  private showPanel !: boolean

  created() {
    store.dispatch("getCategories")
  }

  private toggleBackdrop (): void {
    if(this.showPanel){
      store.commit('togglePanel')
    }

  }
}
