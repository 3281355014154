
import {Options, Vue} from "vue-class-component";
import {Color, CustomDropDownItem, Presentation, ProductDetail, StockOption} from "@/types";
import vSelect from 'vue-select'
import {mdbCarousel} from 'mdbvue'
import {mdbModal, mdbModalBody} from 'mdbvue'
import CustomDropdown from "@/components/CustomDropdown.vue";
import PhotoOptionCarousel from "@/components/PhotoOptionCarousel.vue";
import GLOBAL from "@/config/global";
import ProductCarousel from "@/components/ProductCarousel.vue"
import TheGarmentPopup from "@/components/TheGarmentPopup.vue"
import TheProductTabs from "@/components/TheProductTabs.vue"
import InnerImageZoom from 'vue-inner-image-zoom'
import {PropType} from "vue";

@Options({
  components: {
    ProductCarousel,
    TheGarmentPopup,
    PhotoOptionCarousel,
    CustomDropdown,
    vSelect,
    mdbCarousel,
    mdbModalBody,
    mdbModal,
    TheProductTabs,
    InnerImageZoom
  },
  props: {
    product: Object as PropType<ProductDetail>,
    frequents: Boolean,
  }
})
export default class ProductCardSelectable extends Vue {

  product !: ProductDetail
  currentPrice = 0
  currentColor: Color | null = null
  currentPresentation: Presentation | null = null
  currentPhoto = ""
  sizeOptions: CustomDropDownItem[] = [];
  colorOptions: CustomDropDownItem[] = [];
  nameColor = ""
  sizeSelected = "Seleccionar..."
  colorSelected = "Seleccionar..."
  tabMeasureSelected = 0
  tabProductSelected = 0
  sizeSelectable = true
  siteUrl = GLOBAL.PUBLIC_URL
  related = []
  combos = []

  created() {
    this.initProduct()
  }

  async initProduct() {
    this.currentPhoto = GLOBAL.PUBLIC_URL + this.product?.colors[0].photos[0].path
    this.selectColor({
      class: "",
      label: this.product?.colors[0].name,
      value: this.product?.colors[0].id
    })
    this.setColorOptions()
    this.setPresentationOptions()
    if (this.product?.attributes.dimensions === "onlyone" && this.product?.presentations[0]) {
      this.sizeSelectable = false
      this.selectPresentation({
        class: "",
        label: this.product?.presentations[0].name,
        value: this.product?.presentations[0].id
      })
    }
  }

  get price(): number {
    if (this.currentColor && this.currentPresentation) {
      this.currentPrice = this.product?.stock[this.currentColor.id][this.currentPresentation.id].price ?? 0
    } else {
      this.currentPrice = this.product?.price ?? 0
    }
    if (this.product?.offer) {
      if (this.product.offer.percent > 0 && this.product.offer.method === '0') {
        return this.currentPrice * (1 - (Number(this.product.offer.percent) / 100))
      }
      if (this.product.offer.percent > 0 && this.product.offer.method === '1') {
        return this.currentPrice
      }
    }
    return this.currentPrice
  }

  get strike(): unknown {
    if (this.product?.offer) {
      if (this.product.offer.percent > 0 && this.product.offer.method === '0') {
        return this.currentPrice
      }
      if (this.product.offer.percent > 0 && this.product.offer.method === '1') {
        return (this.currentPrice / (1 - (this.product.offer.percent / 100))).toFixed(2)
      }
    }
    return null
  }

  selectColor(color: CustomDropDownItem) {
    this.currentColor = null
    this.colorSelected = color.label
    if (color.value > 0) {
      this.product?.colors.forEach(item => {
        if (item.id === color.value) {
          this.currentColor = item
          this.currentPhoto = GLOBAL.PUBLIC_URL + item.photos[0].path
        }
      })
      this.setPresentationOptions()

      if (this.currentPresentation &&
          this.product &&
          this.product?.stock[color.value] &&
          this.product?.stock[color.value][this.currentPresentation.id] &&
          this.product?.stock[color.value][this.currentPresentation.id].stock > 0) {
        // do nothing
      }else{
        this.selectPresentation({
          label: "Selecionar...",
          class: '',
          value: 0
        })
      }
    }

  }

  setColorOptions() {
    this.colorOptions = []
    this.product?.colors.forEach((color) => {
      color.class = "text-lightgray"
      if(!this.currentPresentation) {
        color.class = ""
      }else{
        if (this.currentPresentation && this.product && this.product?.stock[color.id] && this.product?.stock[color.id][this.currentPresentation.id]) {
          const stockOption: StockOption = this.product?.stock[color.id][this.currentPresentation.id]
          if (stockOption.stock > 0) {
            color.class = ""
          }
        }
      }
      this.colorOptions.push({
        label: color.name,
        class: color.class,
        value: color.id
      })
    })

  }

  selectPresentation(presentation: CustomDropDownItem) {
    this.currentPresentation = null
    this.sizeSelected = presentation.label
    if (presentation.value > 0) {
      this.product?.presentations.forEach(item => {
        if (item.id === presentation.value) {
          this.currentPresentation = item
        }
      })
      this.setColorOptions()

      if (this.currentColor &&
          this.product &&
          this.product?.stock[this.currentColor.id] &&
          this.product?.stock[this.currentColor.id][presentation.value] &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.product?.stock[this.currentColor!.id][presentation.value].stock > 0) {
        // do nothing
      }else{
        //
        this.selectFirstColorAvailable()
      }
    }else{
      this.setColorOptions()
    }
  }

  selectFirstColorAvailable() {
    for(let i = 0; i < this.colorOptions.length; i++) {
      if(this.colorOptions[i].class == '') {
        this.selectColor(this.colorOptions[i]);
        break;
      }
    }
  }

  setPresentationOptions() {
    this.sizeOptions = []
    this.product?.presentations.forEach((presentation) => {
      presentation.class = "text-lightgray"
      if(!this.currentColor){
        presentation.class = ''
      }
      if (this.currentColor && this.product && this.product?.stock[this.currentColor.id] && this.product?.stock[this.currentColor.id][presentation.id]) {
        const stockOption: StockOption = this.product?.stock[this.currentColor.id][presentation.id]
        if (stockOption.stock > 0) {
          presentation.class = ""
        }
      }
      this.sizeOptions.push({
        label: presentation.name,
        class: presentation.class,
        value: presentation.id
      })
    })

  }

  updatePreview(url: string): void {
    this.currentPhoto = url
  }
}
