
import {Vue} from 'vue-class-component';
import {login} from "@/data/apiSession";
import router from "@/router";
import {store} from "@/store";
import Session from "@/services/session";

export default class LoginForm extends Vue {

  email = ""
  password = ""
  message = ""

  goToRecovery(): void {
    this.$emit("toRecovery")
  }

  goToRegister(): void {
    this.$emit("toRegister")
  }

  async submitLogin(e: Event) {
    e.preventDefault();
    store.commit('showLoading', 'Iniciando sesión...')
    const {code, message} = await login({email: this.email, pass: this.password})
    store.commit('hideLoading')
    if (code === 200) {
      store.commit('showLoading', 'Ingresando con su cuenta...')
      console.log(Session.getToken())
      router.go(0)
    }else{
      this.message = message
    }
  }
}
