
import {User, Region, Province, District, Address} from "@/types";
import {Vue} from "vue-class-component";
import Session from "@/services/session";
import {store} from "@/store";
import {postAddress, putProfile} from "@/data/apiUser";
import Api from "@/services/api";

export default class AccountMain extends Vue {

  tabAccount = 0
  firstName = ""
  lastName = ""
  firstEmail = ""
  currentPhone = ""
  loggedIn = Session.isLoggedIn()
  user !: User
  message = ""
  passOne = ""
  passTwo = ""
  modelReason = ""
  modelDni = ""
  modelRegion = ""
  modelProvince = ""
  modelDistrict = ""
  modelStreet = ""
  modelReference = ""
  listRegion: Region[] = []
  listProvince: Province[] = []
  listDistrict: District[] = []
  listAddress: Address[] = []
  hiddenDiv = ""
  type = ""


  created() {
    if (this.loggedIn) {
      this.user = Session.getUser()
      if (this.user.attributes.name) {
        this.firstName = this.user.attributes.name.toString()
      }
      if (this.user.attributes.surname) {
        this.lastName = this.user.attributes.surname.toString()
      }
      if (this.user.email) {
        this.firstEmail = this.user.email.toString()
      }
      if (this.user.attributes.phone) {
        this.currentPhone = this.user.attributes.phone.toString()
      }


    }

    this.getRegion()
    this.getAddress()


  }


  async getRegion() {
    const response = await Api.get("/region")
    this.listRegion = response.data
    console.log(this.listRegion)
  }

  async getProvince(idRegion: number) {
    const response = await Api.get("/province?idRegion=" + idRegion)

    this.listProvince = response.data
    console.log(this.listProvince)
  }

  async getDistrict(idProvince: number) {
    const response = await Api.get("/district?idProvince=" + idProvince)

    this.listDistrict = response.data
    console.log(this.listDistrict)
  }

  selectRegion() {
    const idRegion = parseInt(this.modelRegion)
    this.getProvince(idRegion)
  }

  selectProvince() {
    const idProvince = parseInt(this.modelProvince)
    this.getDistrict(idProvince)
  }

  async getAddress() {
    const response = await Api.get("/address")
    this.listAddress = response.data
    console.log(this.listAddress)
  }


  async submitProfile(e: Event) {
    e.preventDefault()
    const attributes: Record<string, unknown> = {
      name: this.firstName,
      phone: this.currentPhone,
      surname: this.lastName
    }
    const body: Record<string, unknown> = {
      attributes: attributes
    }
    store.commit('showLoading', 'Guardando nuevo perfil')
    const {code, message} = await putProfile(body)
    store.commit("hideLoading")
    if(code !== 200) {
      this.$toast.error(message)
    }
  }

  submitPassword(e: Event): void {
    e.preventDefault();
  }


  async submitAddress(e: Event) {
    e.preventDefault();
    const body: Record<string, unknown> = {
      name: this.modelReason,
      dni: this.modelDni,
      region: this.modelRegion,
      province: this.modelProvince,
      idDistrict: this.modelDistrict,
      address: this.modelStreet,
      reference: this.modelReference,
      type: this.type,
    }

    store.commit('showLoading', 'Guardando nueva direccion')
    const {code, message} = await postAddress(body)
    store.commit("hideLoading")
    if(code !== 200) {
      this.$toast.error(message)
    }
  }

  closeSession(): void {
    Session.logout()
  }

}

