import axios from "axios"
import Endpoint from "@/config/endpoint"
import { getCookie } from "@/util/cookies"

const HTTP = axios.create({
    baseURL: Endpoint.BASE_URL,
    headers: {
        Authorization: `Bearer ${getCookie('auth-token')}`
    }
})
export default class Api {

    static async get(url: string) {
        const response = await HTTP.get(url).catch((error) => {
            return error.response
        })
        return response.data
    }

    static async post(url: string, body: Record<string, unknown> | FormData) {
        const response = await HTTP.post(url, body).catch((error) => {
            return error.response
        })
        return response.data
    }

    static async put(url: string, body: Record<string, unknown>) {
        const response = await HTTP.put(url, body).catch((error) => {
            return error.response
        })
        return response.data
    }

    static async delete(url: string) {
        const response = await HTTP.delete(url).catch((error) => {
            return error.response
        })
        return response.data
    }

}
