
import {Vue} from "vue-class-component";
import {register} from "@/data/apiUser";
import {store} from "@/store";
import Session from "@/services/session";

export default class RegisterMain extends Vue {

  name = ""
  surname = ""
  phone = ""
  email = ""
  password = ""
  passwordConfirm = ""
  checkAds = false
  checkTerms = false
  by = "app-web"
  messagePassword = ""
  attributes = {
    // requirements
    legallyConstitutedCompany: false,
    locatedInLimaOrCallao: false,
    abilityToAcceptReturnsAndWarranties: false,
    refurbishedLegalProducts: false,
    useAutomaticGuides: false,
    // trademarkRepresentation
    categoryOfMostOfYourProducts: '',
    firstMajorBrand: '',
    secondCategoryOfMostOfYourProducts: '',
    secondMajorBrand: '',
    // companyInformation
    kindOfSociety: '',
    businessName: '',
    postalCode: 0,
    companyInformationCountry: '',
    companyInformationDistrict: '',
    companyInformationCity: '',
    companyInformationAddress: '',
    fullNameOfTheLegalRepresentative: '',
    companyInformationRuc: '',
    companyInformationRucFile: null,
    companyInformationDniFile: null,
    billOfSaleFile: null,
    // bankData
    accountHolder: '',
    bank: '',
    interbankAccountCode: '',
    bankDocumentFile: null,
    accountNumber: '',
    accountType: '',
    dniOrRucOfTheAccountHolder: '',
    // warehouseContacts
    contactPerson: '',
    placeNumber: '',
    warehouseContactsDistrict: '',
    warehouseContactsCity: '',
    warehouseContactsPostalCode: '',
    warehouseContactsStreet: '',
    warehouseContactsUrbanization: '',
    referencePointOfYourWarehouse: '',
    warehouseContactsPhone: '',
    warehouseContactsEmail: '',
  }
  options = {
    categoryOfMostOfYourProducts: [
      { value: '', name: 'Elige una opción' },
      { value: 'Appliances', name: 'Electrodomésticos' },
      { value: 'Books', name: 'Libros' },
      { value: 'Cellphones', name: 'Celulares y Tablets' },
      { value: 'Computing', name: 'Computación' },
      { value: 'Fashion', name: 'Moda' },
      { value: 'Health&Beauty', name: 'Belleza y cuidado personal' },
      { value: 'Home', name: 'Hogar' },
      { value: 'Kids&Babies', name: 'Niños y bebés' },
      { value: 'Photography', name: 'Fotografía' },
      { value: 'Sports', name: 'Deportes' },
      { value: 'TV/Audio/Video', name: 'TV/Audio/Video' },
      { value: 'Videogames', name: 'Videojuegos' },
      { value: 'Other', name: 'Otro' }
    ],
    kindOfSocieties: [
      { value: '', name: 'Elige una opción' },
      { value: 'Sociedad Anónima Cerrada', name: 'Sociedad Anónima Cerrada' },
      { value: 'S.R.L.', name: 'S.R.L.' },
      { value: 'Sociedad Anónima', name: 'Sociedad Anónima' },
      { value: 'Persona natural con negocio', name: 'Persona natural con negocio' },
      { value: 'Empresa Individual de Responsabilidad Limitada', name: 'Empresa Individual de Responsabilidad Limitada' },
    ],
    countries: [
      { value: '', name: 'Elige una opción' },
      { value: 'Peru', name: 'Perú' },
    ],
    banks: [
      { value: '', name: 'Elige una opción' },
      { value: 'BANCO AZTECA', name: 'BANCO AZTECA' },
      { value: 'BANCO CENCOSUD', name: 'BANCO CENCOSUD' },
      { value: 'BANCO DE COMERCIO', name: 'BANCO DE COMERCIO' },
      { value: 'BANCO DE CREDITO DEL PERU', name: 'BANCO DE CREDITO DEL PERU' },
      { value: 'BANCO FALABELLA', name: 'BANCO FALABELLA' },
      { value: 'BANCO GNB PERU', name: 'BANCO GNB PERU' },
      { value: 'BANCO INTERAMERICANO DE FINANZAS (BANBIF)', name: 'BANCO INTERAMERICANO DE FINANZAS (BANBIF)' },
      { value: 'BANCO PICHINCHA', name: 'BANCO PICHINCHA' },
      { value: 'BANCO RIPLEY', name: 'BANCO RIPLEY' },
      { value: 'BANCO SANTANDER PERU', name: 'BANCO SANTANDER PERU' },
      { value: 'BBVA CONTINENTAL', name: 'BBVA CONTINENTAL' },
      { value: 'CITIBANK PERU', name: 'CITIBANK PERU' },
      { value: 'ICBC PERU BANK', name: 'ICBC PERU BANK' },
      { value: 'INTERBANK', name: 'INTERBANK' },
      { value: 'MIBANCO', name: 'MIBANCO' },
      { value: 'SCOTIABANK PERU', name: 'SCOTIABANK PERU' },
    ],
    warehouseContactsCities: [
      { value: '', name: 'Elige una opción' },
      { value: 'Lima|Lima|Lima', name: 'Lima' },
      { value: 'Callao|Callao|Callao', name: 'Callao' },
    ]
  }

  created() {
    if(Session.isLoggedIn()) {
      document.location.href = "/"
    }
  }

// eslint-disable-next-line
  onFileRuc(el: any) {
    try {
      this.attributes.companyInformationRucFile = el ? el.$refs.companyInformationRucFile.files[0]: null
    } catch (error) {
      console.log('Something went wrong onFileRuc:', error)
      this.$toast.error('No se pudo subir el RUC')
    }
  }

// eslint-disable-next-line
  onFileDni(el: any) {
    try {
      this.attributes.companyInformationDniFile = el.$refs.companyInformationDniFile.files[0]
    } catch (error) {
      console.log('Something went wrong onFileDni:', error)
      this.$toast.error('No se pudo subir el DNI')
    }
  }

// eslint-disable-next-line
  onFileBillOfSale(el: any) {
    try {
      this.attributes.billOfSaleFile = el.$refs.billOfSaleFile.files[0]
    } catch (error) {
      console.log('Something went wrong onFileBillOfSale:', error)
      this.$toast.error('No se pudo subir el Comprobante')
    }
  }

// eslint-disable-next-line
  onFileBankDocument(el: any) {
    try {
      this.attributes.bankDocumentFile = el.$refs.bankDocumentFile.files[0]
    } catch (error) {
      console.log('Something went wrong onFileBankDocument:', error)
      this.$toast.error('No se pudo subir el Documento')
    }
  }

  async submitRegister(e: Event) {
    e.preventDefault();
    if (this.password === this.passwordConfirm) {
      const attribute: Record<string, unknown> = {
        name: this.name,
        phone: this.phone,
        surname: this.surname,
        ads: (this.checkAds)? 1 : 0,
        ...this.attributes,
        legallyConstitutedCompany: (this.attributes.legallyConstitutedCompany) ? 1 : 0,
        locatedInLimaOrCallao: (this.attributes.locatedInLimaOrCallao) ? 1 : 0,
        abilityToAcceptReturnsAndWarranties: (this.attributes.abilityToAcceptReturnsAndWarranties) ? 1 : 0,
        refurbishedLegalProducts: (this.attributes.refurbishedLegalProducts) ? 1 : 0,
        useAutomaticGuides: (this.attributes.useAutomaticGuides) ? 1 : 0,
      }
      delete attribute.companyInformationRucFile
      delete attribute.companyInformationDniFile
      delete attribute.billOfSaleFile
      delete attribute.bankDocumentFile
      const body = new FormData()
      body.append('state', '2')
      body.append('idType', '2')
      body.append('email', this.email)
      body.append('password', this.password)
      body.append('by', this.by)
      body.append('attributes', JSON.stringify(attribute))
      if (this.attributes.companyInformationRucFile) {
        body.append('companyInformationRucFile', this.attributes.companyInformationRucFile)
      }
      if (this.attributes.companyInformationDniFile) {
        body.append('companyInformationDniFile', this.attributes.companyInformationDniFile)
      }
      if (this.attributes.billOfSaleFile) {
        body.append('billOfSaleFile', this.attributes.billOfSaleFile)
      }
      if (this.attributes.bankDocumentFile) {
        body.append('bankDocumentFile', this.attributes.bankDocumentFile)
      }
      store.commit('showLoading', 'Registrando nuevo vendedor...')
      const {code, message} = await register(body)
      if(code === 200) {
        document.location.href = "/"
      }else{
        store.commit('hideLoading')
        this.$toast.error(message)
      }
    } else {
      this.messagePassword = 'Confirme correctamente su contraseña.'
    }
  }

}

