
import {Options, Vue} from "vue-class-component";
import { SlidePhoto} from "@/types";
import {PropType} from "vue";

@Options({
  props: {
    slides : Object as PropType<SlidePhoto[]>
  }
})
export default class PhotoOptionCarousel extends Vue {
  private slides !: SlidePhoto[]
  private rendered: SlidePhoto[] = []
  private start = 0
  private end = 5

  created() {
    this.initPhotos()
  }

  mounted() {
    this.initPhotos()
  }

  initPhotos(): void {
    let i = 0
    this.slides.forEach((slide, index) => {
      if(index >= this.start && index <= this.end){
        this.rendered[i] = slide
        // this.$set(this.rendered, i, slide)
        i++
      }
    })
  }

  updatePhoto(url: string) {
    this.$emit("changePreview", url)
  }

  prev(): void {
    if(this.start > 0) {
      this.start--
      this.end--
      this.initPhotos()
    }
  }

  next(): void {
    if(this.end < this.slides.length - 1) {
      this.start++
      this.end++
      this.initPhotos()
    }
  }
}
