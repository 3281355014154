
import {Options, Vue} from 'vue-class-component';
import {recovery} from "@/data/apiSession";
import {store} from "@/store";
import vueRecaptcha from 'vue3-recaptcha2';
import router from "@/router";

@Options({
  components: {vueRecaptcha}
})
export default class RecoveryUpdate extends Vue {

  password = ""
  passwordConfirm = ""
  identity = ""
  message = ""
  verifiedRecaptcha = false
  passwordUpdated = false

  created() {
    if(router.currentRoute.value.query.q) {
      this.identity = router.currentRoute.value.query.q.toString()
    }else{
      router.push("/")
    }
  }

  async submitPassword(e: Event) {
    e.preventDefault();
    if(this.password !== this.passwordConfirm) {
      this.message = "Las contraseñas no coinciden"
      setTimeout(() => {
        this.message = ''
      }, 3000);
      return
    }
    store.commit('showLoading', 'Actualizando su contraseña...');
    const {code, message} = await recovery({password: this.password, identity: this.identity})
    if(code === 200) {
      this.passwordUpdated = true
    }else{
      this.message = message
    }
    store.commit('hideLoading')

  }

  recaptchaVerified(response: boolean) {
    this.verifiedRecaptcha = response
  }

  recaptchaExpired() {
    this.verifiedRecaptcha = false
    // this.$refs.vueRecaptcha.reset()
  }

  recaptchaFailed() {
    console.log('failed')
    this.verifiedRecaptcha = false
  }

}
