import Api from "@/services/api";

export const getCategories = async () => {
    const response = await Api.get('/home')
    if(response.categories)
        return response.categories
    return []
}

export const getProductsOnFlash = async () => {
    const response = await Api.get('/flash')
    if(response.onFlash)
        return response.onFlash
    return []
}

export const getSlider = async () => {
    const response = await Api.get('/banner')
    return response
}
