
import {Options, Vue} from 'vue-class-component';
import {requestRecovery} from "@/data/apiSession";
import vueRecaptcha from 'vue3-recaptcha2';
import {store} from "@/store";

@Options({
  components: {vueRecaptcha},
})
export default class RecoveryMain extends Vue {

  email = ""
  message = ""
  verifiedRecaptcha = false
  requested = false

  async submitRequest(e: Event) {
    e.preventDefault();
    this.message = ''
    store.commit('showLoading', 'Enviando correo...');
    const {code, message} = await requestRecovery({email: this.email})
    if (code == 200) {
      this.requested = true
    } else {
      this.message = message
    }
    store.commit('hideLoading')
  }

  recaptchaVerified(response: boolean) {
    this.verifiedRecaptcha = response;
    // this.$refs.vueRecaptcha.reset()
  }

  recaptchaExpired() {
    this.verifiedRecaptcha = false;
  }

  recaptchaFailed() {
    console.log('failed')
    this.verifiedRecaptcha = false
  }

}
