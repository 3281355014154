
import {Age, Brand, Category, ColorProduct, Product, Size} from "@/types";
import {mapState} from "vuex";
import ProductCard from "@/components/ProductCard.vue";
import {Options, Vue} from "vue-class-component";
import {store} from "@/store";
import {getSearch} from "@/data/apiSearch";
import router from "@/router";

@Options({
  components: {ProductCard},
  computed: mapState(['searching']),
  watch: {
    searching(value: string) {
      this.query.reference = value
      if (value != '') {
        this.query.category = ''
        this.pushSearch()
      }
    },
    currentQuery(value) {
      store.state.searched = value.reference ?? ''
      // por si usan el panel
      this.query.category = value.category ?? ''
      this.search()
    }
  }
})
export default class SearchMain extends Vue {

  categories: Category[] = []
  products: Product[] = []

  filters = ["brand"]
  sizes: Size[] = []
  brands: Brand[] = []
  colors: ColorProduct[] = []
  ages: Age[] = []

  query = {
    category: "",
    brand: "",
    size: "",
    sort: "relevance",
    gender: "",
    age: "",
    color: "",
    reference: ""
  }

  mounted() {
    if (router.currentRoute.value.query.reference){
      store.state.searched = router.currentRoute.value.query.reference.toString()
      this.query.reference = router.currentRoute.value.query.reference.toString()
    }
    this.query.category = router.currentRoute.value.query.category ? router.currentRoute.value.query.category.toString() : ''
    this.search()
  }

  unmounted() {
    store.state.searching = ''
    store.state.searched = ''
  }

  get currentQuery() {
    return router.currentRoute.value.query
  }

  pushSearch() {
    router.push({ path: 'search', query: this.query })
  }

  async search() {
    store.commit('showLoading')
    const query = "category=" + this.query.category +
        "&brand=" + this.query.brand +
        "&size=" + this.query.size +
        "&sort=" + this.query.sort +
        "&gender=" + this.query.gender +
        "&age=" + this.query.age +
        "&color=" + this.query.color +
        "&reference=" + this.query.reference.replace(/[`~!@#$%^&*()_|+\-=?;:'"´,.<>{}[\]\\/]/gi, '')
    const {code, message, payload} = await getSearch(query)
    if (code === 200) {
      this.products = []
      this.products = payload.products
      if(payload.categories && store.state.searchingByPanel) {
        this.categories = payload.categories
        if (this.categories.length === 1) {
          this.categories[0].selected = true
          this.query.category = this.categories[0].slug
          this.filters = this.categories[0].filters ?? []
        }
      }
      this.sizes = payload.sizes ?? []
      this.brands = payload.brands ?? []
      this.colors = payload.colors ?? []
      this.ages = payload.ages ?? []

    } else {
      console.log('message', message)
    }
    store.commit('hideLoading')
  }

  selectCategory(categories: Category[], category: Category) {
    store.state.searchingByPanel = false
    this.query.reference = ''
    categories.map((_category) => {
      if(_category !== category){
        _category.drilled = false
        _category.selected = false
      }else{
        category.drilled = !category.drilled
        category.selected = true
        if(this.query.category != category.slug) {
          this.query.category = category.slug
          this.filters = category.filters ?? []
          this.pushSearch()
        }
      }
      _category.categories.map((subCategory) => {
        subCategory.selected = false
        subCategory.drilled = false
      })
    })
  }

  useFilters() {
    return false
  }

}
