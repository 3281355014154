import { createStore } from 'vuex'
import {Category} from "@/types";
import Api from "@/services/api";
import GLOBAL from "@/config/global";

// define your typings for the store state
export interface State {
  loading: boolean,
  message: string,
  showPanel: boolean,
  categories: Category[],
  reference: string,
  searching: boolean,
  loggedIn: boolean
}

export const store = createStore({
  state: {
    loading: false,
    message: "",
    showPanel: false,
    categories: [] as Category[],
    searching: "",
    searched: "",
    searchingByPanel: true,
    loggedIn: false
  },
  mutations: {
    showLoading(state, message: string) {
      state.loading = true
      if(message)
        state.message = message
    },
    hideLoading(state) {
      state.loading = false
    },
    initCategories(state, categories: Category[]){
      state.categories = categories
    },
    togglePanel(state) {
      state.showPanel = !state.showPanel
    }
  },
  actions: {
    async getCategories() {
      const response = await Api.get("/category")
      const categories = response
      categories.forEach(function (category: Category){
        category.image = GLOBAL.PUBLIC_URL + category.image
        category.drilled = false
        category.selected = false
        category.depth = 0
        category.categories.forEach(function (subcategory){
          if(category.depth === 0)
            category.depth = 1
          subcategory.image = GLOBAL.PUBLIC_URL + subcategory.image
          subcategory.drilled = false
          subcategory.selected = false
          subcategory.categories.forEach(function (subsubcategory){
            category.depth = 2
            subcategory.depth = 1
            subsubcategory.image = GLOBAL.PUBLIC_URL + subsubcategory.image
            subsubcategory.drilled = false
            subsubcategory.selected = false
          })
        })
      })
      this.commit("initCategories", categories)
    }
  }
})
