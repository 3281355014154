import Api from "@/services/api";

export const getProduct = async (idProduct: string) => {
    return await Api.get('/product/' + idProduct)
}

export const postComment = async (body: Record<string, unknown>) => {
    return await Api.post('/comment?', body)
}

export const postQuestion = async (body: Record<string, unknown>) => {
    return await Api.post('/faq', body)

}

export const getRelated = async (idProduct: number) => {
    return await Api.get('/related/' + idProduct)
}

export const getCombo = async (idProduct: number) => {
    return await Api.get('/combo/' + idProduct)
}

export const getFrequent = async (idProduct: number) => {
    return await Api.get('/frequent/' + idProduct)
}
