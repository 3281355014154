
import { Options, Vue } from 'vue-class-component';
  import VueCountdown from '@chenfengyuan/vue-countdown'
  import {ColorOption, Product} from "@/types"
  import {Carousel, Slide} from 'vue3-carousel'
  import ProductColorOption from "@/components/ProductColorOption.vue";
import GLOBAL from "@/config/global";
import {PropType} from "vue";
import router from "@/router";

  @Options({
    components: {
      ColorOptionCarousel: ProductColorOption,
      VueCountdown, Carousel, Slide
    },
    props: {
      product: Object as PropType<Product>
    }
  })
  export default class ProductCard extends Vue {
    hover = false
    thumb = ''
    colors: ColorOption[] = []
    product!: Product

    created() {
      this.thumb = GLOBAL.PUBLIC_URL + this.product.thumb
      if(this.product.colors && this.product.colors.length > 1){
        this.product.colors.forEach((color) => {
          this.colors.push(color)
        })
      }
    }

    get price(): string {
      if (this.product && this.product.percent > 0 && this.product.method === '0') {
        return (Number(this.product.minPrice) * (1 - (Number(this.product.percent) / 100))).toFixed(2)
      }
      if (this.product && this.product.percent > 0 && this.product.method === '1') {
        return this.product.minPrice
      }
      return this.product.minPrice ?? ''
    }

    get strike(): unknown {
      if (this.product && this.product.percent > 0 && this.product.method === '0') {
        return this.product.minPrice
      }
      if (this.product && this.product.percent > 0 && this.product.method === '1') {
        return (Number(this.product.minPrice) / (1 - (this.product.percent / 100))).toFixed(2)
      }
      return null
    }

    get time(): number {
      if (this.product.end) {
        const date = new Date(this.product.end)
        const now = new Date()
        return date.valueOf() - now.valueOf()
      }
      return 0
    }
    private decimalsPrice(price: string) {
      return parseFloat(price).toFixed(2)
    }

    private transform(props: Record<string, unknown>): void {
      Object.entries(props).forEach(([key, value]) => {
        const value_ = value as number
        const digits = value_ < 10 ? `0${value}` : value
        props[key] = `${digits}`
      })
      // return props
    }

    private updatePreview(url: string): void {
      this.thumb = GLOBAL.PUBLIC_URL + url
    }

    private goToDetail(): void {
      //condition type service/product
      router.push("/product/" + this.product.slug + '?color=' + this.product.idProductColor)
    }
  }
