import Api from "@/services/api";
import Session from "@/services/session";

export const login = async (body: Record<string, unknown>) => {
    const { code, message, payload } = await Api.post("/session", body)
    if (code === 200 && payload.token) {
        Session.init(payload.token)
    }
    return { code, message }
}

export const requestRecovery = (body: Record<string, unknown>) => {
    return Api.post('/recovery', body)
}
export const recovery = async (body: Record<string, unknown>) => {
    return Api.put('/recovery', body)
}



