
import {Options, Vue} from 'vue-class-component';
import ProductCard from "@/components/ProductCard.vue"
import {Product} from "@/types"
import {Carousel, Slide} from 'vue3-carousel'
import {PropType} from "vue";

@Options({
  props: {
    name: String,
    link: String,
    products: Object as PropType<Product[]>,
    flash: Boolean
  },
  components: {
    ProductCard: ProductCard,
    Carousel, Slide
  }
})

export default class ProductCarousel extends Vue {
  hover = false
  canNext = true
  canPrev = false
  products!: Product[]

  mounted() {
    const carousel = this.$refs.carousel as Carousel
    if(carousel) {
      this.canNext = (carousel.data.currentSlide.value < carousel.data.maxSlide.value)
      this.canPrev = (carousel.data.currentSlide.value > carousel.data.minSlide.value)
    }
  }

  goToNext(): void {
    const carousel = this.$refs.carousel as Carousel
    console.log(carousel)
    carousel.next()
    console.log('current', carousel.data.currentSlide.value)
    console.log('max', carousel.data.maxSlide.value)
    console.log('min', carousel.data.minSlide.value)
    //if (carousel.currentPage < carousel.getNextPage()) {
    //  carousel.goToPage(carousel.getNextPage())
    //}
    this.canNext = (carousel.data.currentSlide.value < carousel.data.maxSlide.value)
    this.canPrev = (carousel.data.currentSlide.value > carousel.data.minSlide.value)
  }

  goToPrev(): void {
    const carousel = this.$refs.carousel as Carousel
    carousel.prev()
    this.canNext = (carousel.data.currentSlide.value < carousel.data.maxSlide.value)
    this.canPrev = (carousel.data.currentSlide.value > carousel.data.minSlide.value)
  }


}
