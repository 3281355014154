
import {Options, Vue} from "vue-class-component";
import {ProductDetail} from "@/types";
import {mdbCarousel} from 'mdbvue'
import {mdbModal, mdbModalBody} from 'mdbvue'
import {PropType} from "vue";

@Options({
  components: {mdbCarousel, mdbModalBody, mdbModal},
  props: {
    product: Object as PropType<ProductDetail>,
  }
})
export default class TheGarmentPopup extends Vue {

  private product !: ProductDetail
  private modal = false
  private tabMeasureSelected = 0

}
