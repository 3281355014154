import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "pb-2" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBCarousel = _resolveComponent("MDBCarousel")!
  const _component_ProductCarousel = _resolveComponent("ProductCarousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.banners.length > 0)
        ? (_openBlock(), _createBlock(_component_MDBCarousel, {
            key: 0,
            class: "mb-5",
            id: "carouselHome",
            items: _ctx.banners,
            fade: ""
          }, null, 8, ["items"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.onFlash.length > 0)
      ? (_openBlock(), _createBlock(_component_ProductCarousel, {
          key: 0,
          products: _ctx.onFlash,
          name: "Ofertas Flash!",
          link: "#",
          flash: true
        }, null, 8, ["products"]))
      : _createCommentVNode("", true),
    (_ctx.onFlash.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
            return (_openBlock(), _createBlock(_component_ProductCarousel, {
              key: category.id,
              products: category.products,
              name: category.name,
              link: "#"
            }, null, 8, ["products", "name"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}