
import {Options, Vue} from "vue-class-component";
import {CustomDropDownItem} from "@/types";

@Options({
  props: {
    tabindex: 0,
    options: [],
    selected: '',
    selectEmpty: Boolean,
    width100: Boolean
  }
})
export default class CustomDropdown extends Vue{
  private open = false
  private optionDefault: CustomDropDownItem = {
    value: 0,
    label: "Seleccionar",
    class: ""
  }

  private selectOption(option: CustomDropDownItem): void {
    //this.selected = option.label
    this.open = false;
    this.$emit("selectOption", option)
  }

  private hide(): void{
    this.open = false
  }
}
