import VueJwtDecode from "vue-jwt-decode";
import {User} from "@/types";


export default class Session {
    static init(token: string) {
        localStorage.setItem("token", token)
    }

    static logout() {
        localStorage.removeItem("token")
        document.location.href = "/";//location.reload()
    }

    static getToken() {
        return localStorage.getItem("token") || ""
    }
    static isLoggedIn() {
        const token = this.getToken()
        if (token !== "") {
            const payload = VueJwtDecode.decode(token)
            return (Date.now() < payload.exp)
        } else {
            return false
        }
    }
    static getUser(): User {
        const token = this.getToken()
        const payload = VueJwtDecode.decode(token)
        return payload.user
    }

}
