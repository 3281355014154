
import { Options, Vue } from 'vue-class-component';
import {mapState} from "vuex"
import {Category, Product} from "@/types"
import router from "@/router";
import ProductCard from "@/components/ProductCard.vue";
import {store} from "@/store";

@Options({
  components: {ProductCard},
  computed: mapState(["categories", "showPanel"]),
  watch: {
    categories(list: Category[]) {
      if(list.length > 0){
        this.hoverCategory(list[0])
      }
    }
  }
})

export default class TheCategoryPanel extends Vue {

  categories !: Category[]
  showPanel !: boolean
  theCategory: Category | null = null
  currentCategory: Category | null = null
  currentSubCategory: Category | null = null
  subCategories: Category[] = []
  currentFeaturedProducts: Product[] = []
  banner = ""
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bannerSlug = ""

  toggleBackdrop(): void {
    if (this.showPanel) {
      store.commit('togglePanel')
    }
  }

  goTo(slug: string): void {
    this.toggleBackdrop()
    store.state.searched = ''
    store.state.searching = ''
    store.state.searchingByPanel = true
    router.push("/search?category=" + slug)
  }

  getCategoryProduct (): Category[] {
    return this.categories.filter(category => category.service == 0)
  }

  getCategoryService (): Category[] {
    return this.categories.filter(category => category.service == 1)
  }

  bannerClick () {
    if (this.bannerSlug) {
      this.goTo(this.bannerSlug);
    }
  }

  hoverCategory(category: Category): void {
    this.theCategory = category
    this.banner = category.image
    this.bannerSlug = category.slug
    this.subCategories = []
    this.currentCategory = category

    this.currentSubCategory = null
    if (category.categories.length > 0) {
      category.categories.forEach((subCategory, index) => {
        this.subCategories[index] = subCategory
        // this.$set(this.subCategories, index, subCategory)
      })
    }
  }

  hoverSubCategory(category: Category): void {
    this.theCategory = category
    this.currentSubCategory = category
  }

  hoverSubSubCategory(category: Category): void {
    this.theCategory = category
  }

  setFeaturedProducts(category: Category): void {
    this.currentFeaturedProducts = []
    this.currentFeaturedProducts.length = 0
    if (category.products.length > 0) {
      category.products.forEach((product, index) => {
        this.currentFeaturedProducts[index] = product
        // this.$set(this.currentFeaturedProducts, index, product)
      })
    }
  }
}
