
import { Options, Vue } from 'vue-class-component';
import {ColorOption} from "@/types";
import GLOBAL from "@/config/global";
import {PropType} from "vue";

@Options({
  props: {
    slug: String,
    thumb: String,
    colors: Object as PropType<ColorOption[]>
  }
})
export default class ProductColorOption extends Vue {
  slug!: string
  thumb!: string
  colors!: ColorOption[]
  private rendered: ColorOption[] = []
  private start = 0
  private end = 3

  created() {
    this.initOptions()
  }

  initOptions(): void {
    let i = 0
    this.colors.forEach((color, index) => {
      if(index >= this.start && index <= this.end){
        this.rendered[i] = color
        // this.$set(this.rendered, i, color)
        i++
      }
    })
  }

  updateThumb(url: string) {
    this.$emit("changePreview", url)
  }

  theThumb(url: string): string{
    return GLOBAL.PUBLIC_URL + url
  }

  prev(): void {
    if(this.start > 0) {
      this.start--
      this.end--
      this.initOptions()
    }
  }

  next(): void {
    if(this.end < this.colors.length - 1) {
      this.start++
      this.end++
      this.initOptions()
    }
  }
}
