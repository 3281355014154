
import {Vue} from "vue-class-component";
import {register} from "@/data/apiUser";
import {store} from "@/store";
import Session from "@/services/session";

export default class RegisterMain extends Vue {

  name = ""
  surname = ""
  phone = ""
  email = ""
  password = ""
  passwordConfirm = ""
  checkAds = false
  checkTerms = false
  by = "app-web"
  messagePassword = ""

  created() {
    if(Session.isLoggedIn()) {
      document.location.href = "/"
    }
  }

  async submitRegister(e: Event) {
    e.preventDefault();
    if (this.password === this.passwordConfirm) {
      const attribute: Record<string, unknown> = {name: this.name, phone: this.phone, surname: this.surname, ads: (this.checkAds)? 1 : 0}
      const body: Record<string, unknown> = {
        email: this.email,
        password: this.password,
        by: this.by,
        attributes: attribute
      }
      store.commit('showLoading', 'Registrando nuevo usuario...')
      const {code, message} = await register(body)
      if(code === 200) {
        document.location.href = "/"
      }else{
        store.commit('hideLoading')
        this.$toast.error(message)
      }
    } else {
      this.messagePassword = 'Confirme correctamente su contraseña.'
    }
  }

}

