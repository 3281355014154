import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeMain from "@/views/Home/HomeMain.vue";
import RecoveryMain from "@/views/Recovery/RecoveryMain.vue";
import RecoveryUpdate from "@/views/Recovery/RecoveryUpdate.vue";
import VueBodyClass from "vue-body-class";
import Session from "@/services/session";
import AccountMain from "@/views/Account/AccountMain.vue";
import RegisterMain from "@/views/Register/RegisterMain.vue";
import SellerMain from "@/views/Register/Seller/SellerMain.vue";
import RegisterPrivacyPolicy from "@/views/Register/RegisterPrivacyPolicy.vue";
import RegisterTermsConditions from "@/views/Register/RegisterTermsConditions.vue";
import SearchMain from "@/views/Search/SearchMain.vue";
import ProductMain from "@/views/Product/ProductMain.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeMain,
    meta: { bodyClass: "home-main" },
  },
  {
    path: "/password",
    name: "password",
    component: RecoveryMain,
    meta: { bodyClass: "password-main" },
  },
  {
    path: "/password/recovery",
    name: "passwordRecovery",
    component: RecoveryUpdate,
    meta: { bodyClass: "password-recovery" },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterMain,
    meta: { bodyClass: "register-main" },
  },
  {
    path: "/register/seller",
    name: "Seller",
    component: SellerMain,
    meta: { bodyClass: "register-main" },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: RegisterPrivacyPolicy,
    meta: { bodyClass: "register-privacy-policy" },
  },
  {
    path: "/terms-conditions",
    name: "Terms & conditions",
    component: RegisterTermsConditions,
    meta: { bodyClass: "register-terms-conditions" },
  },
  {
    path: "/account",
    name: "Account",
    component: AccountMain,
    meta: { bodyClass: "account-main" },
  },
  {
    path: "/search",
    name: "Search",
    component: SearchMain,
    meta: { bodyClass: "search-main" },
  },
  {
    path: "/product/:slug",
    name: "Product",
    component: ProductMain,
    meta: { bodyClass: "product-main" },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
  const privatePages = ["/profile"];
  const withOutLogin = [""];

  if (privatePages.includes(to.path) && !Session.isLoggedIn()) {
    return next("/");
  }

  if (withOutLogin.includes(to.path) && Session.isLoggedIn()) {
    return next("/");
  }

  // next();
});

export default router
