import Api from "@/services/api";

export const register = async (body: Record<string, unknown> | FormData) => {
    const {code, message, payload} = await Api.post("/user", body)
    if (code === 200 && payload.token) {
        localStorage.setItem("token", payload.token)
    }
    return {code, message}
}

export const putProfile = async (body: Record<string, unknown>) => {
    return await Api.put("/user", body)
        .then((response) => {
            const {code, message, payload} = response.data
            if (payload.token) {
                localStorage.setItem("token", payload.token)
            }
            return {code, message}
        })
        .catch((error) => {
            return error.response.data
        })
}
export const postAddress = async (body: Record<string, unknown>) => {
    return await Api.post("/address", body)
        .then((response) => {
            const {code, message, payload} = response.data
            if (payload.token) {
                localStorage.setItem("token", payload.token)
            }
            return {code, message}
        })
        .catch((error) => {
            return error.response.data
        })
}
